import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCz0LBwXp-oswivBv9wTY6dYbusIjEu-Ro",
  authDomain: "application-9f94f.firebaseapp.com",
  projectId: "application-9f94f",
  storageBucket: "application-9f94f.appspot.com",
  messagingSenderId: "761245150661",
  appId: "1:761245150661:web:89961ee11100c39ff4afac"
};

const app = initializeApp(firebaseConfig)

const auth = getAuth(app)

export { auth }