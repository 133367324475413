<template>
  <main class="home">
    <blur class="blur">
    <header class="headerText">
      <h1 v-if="!$store.state.user">Forside</h1>
      <h1 v-if="$store.state.user">Velkommen til denne video</h1>
    </header>
    <disclaimer class="notLoggedIn" v-if="!$store.state.user">
      <p>Du er nødt til at logge ind, for at se indhold på denne side</p>
      <button v-if="!$store.state.user" @click="$store.dispatch('loginside')">Login</button>
    </disclaimer>
    <body class="body" v-if="$store.state.user">
      <section class="sectionBody">
        <video id="video-player" autoplay controls loop>
          <source src="https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4" type="video/mp4">
          Your browser does not support the video tag.
        </video>
      </section>
    </body>
  </blur>
  </main>
</template>

<script>

import { onBeforeMount } from 'vue'
import { useStore } from 'vuex'

export default {
  setup() {
    const store = useStore()

    onBeforeMount(() => {
      store.dispatch('fetchUser')
    })
  },
}
</script>

<style scoped>

.blur {
  display:flex;
  flex-direction: column;
  justify-content: center;
  height: 88vh;
  background-image: radial-gradient(
    rgba(0, 0, 32, 0.25) 50%,
    rgba(0, 0, 0, 0) 75%
  );
}

.notLoggedIn p{
  padding: 25px;
  color: #FFF;
  font-size: xx-large;
  font-weight: bold;
}
.notLoggedIn button {
  background-color: #009de0;
  color: #FFF;
  font-weight: 700;
  font-size: 25px;
  padding: 1.5rem 4rem;
  border-radius: 0.5rem;
  cursor: pointer;
  text-transform: uppercase;
  opacity: 0.8;
}
.notLoggedIn button:hover {
  opacity: 1;
}

.headerText h1 {
  margin-bottom: 25px;
  font-size: 150px;
  color: #FFF;
}
.home {
  min-height: 100vh;
  color: rgb(0, 0, 32);
  background: url("https://ramboll.streamkey.dk/wp-content/uploads/2024/04/image.webp") no-repeat center center fixed; 
  background-size: cover;
}

.sectionBody {
  margin-top: 50px;
  height: 50vh;
}
#video-player {
  aspect-ratio: 16 / 9;
    height: 99%;
}

</style>