<template>
  <nav id="nav" >
    <router-link to="/"><img src="../src/assets/logo.png" alt="streamkey logo"></router-link>
    <router-link to="/">Home</router-link>
    <router-link v-if="$store.state.user" to="/about">About</router-link> 
    <button v-if="!$store.state.user" @click="$store.dispatch('loginside')">Login</button>
    <button v-if="$store.state.user" @click="$store.dispatch('logout')">Logout</button>
  </nav>
  <router-view/>
</template>


<script>
import './css/style.css'

import { onBeforeMount } from 'vue'
import { useStore } from 'vuex'
import router from '/src/router'

export default {
  setup() {
    const store = useStore()

    onBeforeMount(() => {
      router.push('/')
      store.dispatch('fetchUser')
    })
  }
}
</script>



<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#app {
  text-align: center;
  color: #FFF;
}

nav {
  position: absolute;
  width: 100vw;
  height: 100px;
  padding: 25px;
  text-align: right;
  font-size: 35px;
  font-family: HCo Gotham Rounded Bold;
}
nav img {
  height: 50px;
  max-height: 10vw;
  left: 50px;
  position: absolute;
}

nav a {
  font-weight: bold;
  color: black;
  margin: 10px;
  vertical-align: middle;
}
nav button {
  font-weight: bold;
  color: #FFF;
  background-color: #009de0;
  margin: 10px;
  padding: 10px 20px;

  border-radius: 10px;
  font-size: 25px;
  cursor: pointer;
  text-transform: uppercase;
  opacity: 0.8;
}
nav button:hover {
  opacity: 1;
}

nav a.router-link-exact-active {
  color: #fd314c;
}
</style>
